@font-face {
  font-family: 'Abyssinica';
  src: url(./assets/fonts/AbyssinicaSIL-R.ttf);
}

@font-face {
  font-family: 'Noticia-font';
  src: url(./assets/fonts/Noticia-Regular.ttf);
}

* {
  padding: 0;
  margin: 0;
}

:root {
  --text-color: #32100E;
}
html, body {
  height: 100%;
  width: 100%;
  background-color: #F3BE97;
  font-family: 'Noticia-font' !important;
}

#root {
  height: 100%;
  width: 100%;
}

#text-box {
  outline: none;
  font-size: 2.3rem;
  padding: 0.5rem 1rem;
  color: var(--text-color);
  border: 2px solid #754822;
  width: 65%;
}

@media screen and (max-width: 720px) {
  #text-box {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    margin: 0 2rem;
    max-width: 100%;
  }
}